import React, { Component } from 'react'

class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className="header">
                <ul>
                    <li><span className="benefits">Your <strong>Benefits</strong></span></li>
                    <li><span className="learning-development">Your <strong>Learning & Development</strong></span></li>
                    <li><span className="work-environment">Your <strong>Work Environment</strong></span></li>
                    <li><span className="compensation">Your <strong>Compensation</strong></span></li>
                </ul>
            </div>
        );
    }
}

export default Header
