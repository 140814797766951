import React, { Component } from 'react'
import { ReactComponent as Benefits } from '../icons/icon-benefits.svg';
import { ReactComponent as LearningDevelopment } from '../icons/icon-learning-development.svg';
import { ReactComponent as WorkEnvironment } from '../icons/icon-work-environment.svg';
import { ReactComponent as Compensation } from '../icons/icon-compensation.svg';

class CategoryDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    renderDetails = (category) => {
        switch (category) {
            case 'benefits':
                return (
                    <>
                        <Benefits />
                        <div>
                            <span>Benefits</span>
                            <p>Benefits that are competitive, flexible and tailored to your needs, wherever you are in life.</p>
                        </div>
                    </>
                )
                break;
            case 'learning-development':
                return (
                    <>
                        <LearningDevelopment />
                        <div>
                            <span>Learning & Development</span>
                            <p>Learn new skills and develop your career while doing meaningful work.</p>
                        </div>
                    </>
                )
                break;
            case 'work-environment':
                return (
                    <>
                        <WorkEnvironment />
                        <div>
                            <span>Work Environment</span>
                            <p>A safe, inclusive and collaborative work environment where you can be you – and succeed.</p>
                        </div>
                    </>
                )
                break;
            case 'compensation':
                return (
                    <>
                        <Compensation />
                        <div>
                            <span>Compensation</span>
                            <p>Competitive and performance-based compensation and rewards you can feel good about.</p>
                        </div>
                    </>
                )
                break;
        
            default:
                break;
        }
    }

    render() {
        return (
            <div className="category-details">
                {this.renderDetails(this.props.category)}
            </div>
        );
    }
}

export default CategoryDetails
