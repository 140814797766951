import React from 'react'
import './App.css'
import TableContainer from './containers/TableContainer'

function Denied() {
    return (
        <div className="App">
            <h1 style={{ paddingTop: 200, fontSize: 35 }}>Oops!<br/>Seems the formula needs tweaking.<br />Check back later to view the you@BASF periodic table.</h1>
        </div>
    );
}

export default Denied
