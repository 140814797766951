import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { ReactComponent as Star } from '../icons/star.svg';

class ElementCard extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        
    }

    handleCardClick = () => {
        if (this.props.card.Category) {
            window.open(this.props.card.Link, '_blank')
        }
    }
    
    handleStarClick = (e) => {
        e.stopPropagation()
        this.props.toggleFavourite(this.props.card)
    }

    render() {
        return (
            <div className="element-card"
                onMouseEnter={() => this.props.updateDetails(this.props.card)}>
                <div className={`element-card__content-wrapper ${this.props.card.isFavourite ? 'favourite' : ''} ${this.props.card.Category == null ? 'blank' : this.props.card.Category}`} onClick={this.handleCardClick}>
                    <Star className="star" onClick={this.handleStarClick}/>
                    <div className="card-image">
                    {this.props.card.Image
                        ? <img src={this.props.card.Image[0].url} />
                        : null
                    }
                    </div>
                    <div className="content">
                        <h3>{this.props.card.Name}</h3>
                    </div>
                </div>
            </div>
        );
    }
}

export default ElementCard
