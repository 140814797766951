import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { ReactComponent as Remove } from '../icons/remove.svg'

class Favourites extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className="favourites-container" id="favourites" ref={this.props.favRef}>
                <h2>Favorites</h2>
                {this.props.favourites.length <= 0 && 
                    <p className="favourite-disclaimer">You can add favorites by clicking on the star icon on an element.</p>
                }
                <ul className="favourites-list">
                    {
                        this.props.favourites.map(fave => {
                            return (
                                <li className={fave.Category} key={fave.Name}>
                                    <div className="favourite-content">
                                        <Remove className="remove" onClick={() => this.props.removeFavourite(fave)}/>
                                        {fave.Image
                                            ? <ReactSVG
                                                beforeInjection={(svg) => {
                                                    svg.setAttribute('style', 'width: 100px; height: 100px')
                                                    svg.setAttribute('stroke', 'white')
                                                    svg.setAttribute('fill', 'white')
                                                }}
                                                src={fave.Image[0].url} />
                                            : null
                                        }
                                        <div className="favourite-content__text">
                                            <h3>{fave.Name}</h3> 
                                            <p>     
                                                {fave.Description}
                                            </p>
                                            <a href={fave.Link} className={fave.Category} target="_blank" rel="noopener noreferrer">Learn more</a>
                                        </div>
                                        
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            {this.props.favourites.length > 0 &&  
                <button className="clear-favourites" onClick={this.props.clearFavourites}>Clear all favorites <Remove /></button>
            }
            </div>
        );
    }
}

export default Favourites
