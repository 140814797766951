import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'

class ElementDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className={`element-details ${this.props.category}`}>
                {this.props.image
                    ? <ReactSVG
                        src={this.props.image.url} />
                    : null
                }
                <div>
                    <span>{this.props.title}</span>
                    <p>{this.props.details}</p>
                </div>
            </div>
        );
    }
}

export default ElementDetails
